<template>
    <div class="report-container">
        <div :class="generateInvoiceProgress ? 'disabled' : 'content'" class="report-filter">
            <div class="row">
                <div v-if="this.$store.state.userData.access_level > 2" class="col">
                    <div class="label-float">
                        <label :class="bodyForm.userId == null ? '' : 'option-selected'">
                            <span v-if="bodyForm.userId">Choose Client</span>
                            <span v-else> Choose Client</span>
                        </label>
                        <select v-model="bodyForm.userId" placeholder=" ">
                            <option value="null"><span style="color: rgba(0, 0, 0, 0.5)">Choose Client</span></option>

                            <option v-for="(item, index) in allClient" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="label-float">
                        <label :class="payment_method == null ? '' : 'option-selected'">
                            <span v-if="payment_method">Choose Payment Method</span>
                            <span v-else> Select Payment Method</span>
                        </label>
                        <select id="payment" v-model="payment_method" placeholder=" ">
                            <option value=""><span style="color: rgba(0, 0, 0, 0.5)">Select Payment Method</span></option>
                            <option value="COD">Cash on Delivery (COD)</option>
                            <option value="CBD">Cash Before Delivery (CBD)</option>
                            <option value="DFOD">Delivery Fee on Delivery (DFOD)</option>
                            <option value="TOP">Terms of Payment (TOP)</option>
                            <option value="BANK_TRANSFER">BNI Bank Transfer</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="label-float">
                        <Datepicker format="dd/MM/yyyy" :auto-apply="true" :enable-time-picker="false" v-model="bodyForm.start_date" position="center" placeholder="Select Start Period" :start-date="dateNow" :keep-action-row="false" />
                        <label :class="'option-selected'">Start Period</label>
                    </div>
                </div>
                <div class="col">
                    <div class="label-float">
                        <Datepicker format="dd/MM/yyyy" :auto-apply="true" :enable-time-picker="false" v-model="bodyForm.end_date" position="center" placeholder="Select End Period" :start-date="this.bodyForm.start_date" :min-date="this.bodyForm.start_date" :keep-action-row="false" />
                        <label :class="'option-selected'">End Period</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-end px-3 mx-1"><button :disabled="!bodyForm.userId || !bodyForm.start_date || !bodyForm.end_date" @click="submit()" style="float: right" class="mx-2 btn btn-usfc-primary">Submit</button></div>
            </div>
        </div>

        <div class="report-content" :class="generateInvoiceProgress == 2 ? 'mb-5' : ''">
            <div class="title">
                <div style="padding: 22px 0">Receipt Report {{ receipts ? `(${receipts.length})` : "" }}</div>
                <div v-if="receipts && receipts.length > 0" class="export">
                    <span @click="exportExcel()" class="btn btn-usfc-outline"> <img class="blue-filter" style="transform: scale(0.75)" src="/icons/all/twotone/clipboard-export.svg" /> Export </span>
                    <!-- <span @click="generatePDF()" class="btn btn-usfc-outline" :class="isPrinting ? 'disabled' : ''"> <img class="blue-filter" style="transform: scale(0.75)" src="/icons/all/twotone/bill.svg" /> Print </span> -->
                    <!-- <span v-if="[5, 2].includes(this.$store.state.userData.access_level)" :class="{ disabled: !selectedReceipts.length }" @click="showPreview()" class="btn btn-usfc-outline">
                        <img class="blue-filter" style="transform: scale(0.75)" src="/icons/all/twotone/document-text.svg" /> Generate Invoice
                    </span> -->
                </div>
            </div>
            <div class="content">
                <div class="chart">
                    <div v-if="!receipts || receipts?.length == 0">
                        <img src="@/assets/empty_state_pie.svg" style="width: 350px" />
                        <div class="text-center">{{ receipts?.length == 0 ? "No report data is avaliable on the selected data for this client" : "You will see your SLA report here" }}</div>
                    </div>
                    <div v-else class="table-wrapper" :style="isPrinting ? 'margin-bottom: 50vh' : ''">
                        <table id="receipts-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Receipt Number</th>
                                    <th>Shipping Type</th>
                                    <th>Initial KM</th>
                                    <th>Final KM</th>
                                    <th>Total KM</th>
                                    <th>Fuel Consumption (Litre)</th>
                                    <th>Total Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in receipts" :key="item.no" @click="goTo('/receipts/details/' + item.receiptId, item)">
                                    <td>{{ item.no }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.receipt_number }}</td>
                                    <td>{{ item.shipping_type }}</td>
                                    <td>{{ item.initial_km }} KM</td>
                                    <td>{{ item.final_km }} KM</td>
                                    <td>{{ item.total_km }} KM</td>
                                    <td>{{ item.fuel_consumption }} Litre</td>
                                    <td>Rp {{ item.total_cost.toLocaleString() }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="print-wrapper" v-if="arrayChunks && isPrinting" id="print-table">
                        <div v-for="(chunk, index) in arrayChunks" :key="index" class="limiter">
                            <div>Client: {{ getNameById(bodyForm.userId) }} {{ payment_method ? "(" + payment_method + ")" : "(ALL)" }}</div>
                            <div>Date: {{ selectedDateRange }}</div>
                            <table style="width: 100%">
                                <thead style="font-size: 14px; font-weigh: 600">
                                    <tr>
                                        <th style="padding: 12px 6px">#</th>
                                        <th style="padding: 12px 6px">Date</th>
                                        <th style="padding: 12px 6px">Receipt Number</th>
                                        <th style="padding: 12px 6px">Origin</th>
                                        <th style="padding: 12px 6px">Destination</th>
                                        <th style="padding: 12px 6px">Weight</th>
                                        <th style="padding: 12px 6px">Bag</th>
                                        <th style="padding: 12px 6px">Price per kg</th>
                                        <th style="padding: 12px 6px">Additional Fee</th>
                                        <th style="padding: 12px 6px">Unit Price</th>
                                        <th style="padding: 12px 6px">Discount</th>
                                        <th style="padding: 12px 6px">Amount</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 12px">
                                    <tr v-for="item in chunk" :key="item.no" @click="goTo('/receipts/details/' + item.receiptId, item)">
                                        <td style="padding: 6px">{{ item.no }}</td>
                                        <td style="padding: 6px">{{ item.date }}</td>
                                        <td style="padding: 6px">{{ item.receipt_number }}</td>
                                        <td style="padding: 6px">{{ item.origin }}</td>
                                        <td style="padding: 6px">{{ item.destination }}</td>
                                        <td style="padding: 6px">{{ item.weight }}</td>
                                        <td style="padding: 6px">{{ item.bag }}</td>
                                        <td style="padding: 6px">{{ item.price_per_kg }}</td>
                                        <td style="padding: 6px">{{ item.additional_fee }}</td>
                                        <td style="padding: 6px">{{ item.unit_price }}</td>
                                        <td style="padding: 6px">{{ item.discount }}</td>
                                        <td style="padding: 6px">{{ item.amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="generateInvoiceProgress == 2" id="print-invoice">
            <div class="invoice" v-for="(chunk, index) in invoiceChunks" :key="index">
                <div class="header" v-if="index === 0">
                    <div class="addresses">
                        <div class="logo mb-1">
                            <img class="icons" src="/usfc_monogram.svg" style="transform: scale(0.5); object-fit: contain" />
                            <table>
                                <tr class="company-name">
                                    <td>PT UNIVESERV FORTUNA CEMERLANG</td>
                                </tr>
                                <tr class="company-Address">
                                    <td>
                                        <div>JL. BY PASS KM 7</div>
                                        <div>RT. 002 RW.001, KEL. PISANG</div>
                                        <div>KEC. PAUH, KOTA PADANG</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="logo">
                            <div style="line-height: 32px">Bill To</div>
                            <div style="line-height: 32px; width: 19px; text-align: end">:</div>
                            <table>
                                <tr class="company-name">
                                    <td>
                                        {{ getClientData(this.bodyForm.userId)?.company }}
                                    </td>
                                </tr>
                                <tr class="company-Address">
                                    <td>
                                        <div>{{ getClientData(this.bodyForm.userId)?.street }}, {{ getClientData(this.bodyForm.userId)?.district }}</div>
                                        <div>{{ getClientData(this.bodyForm.userId)?.city }}, {{ getClientData(this.bodyForm.userId)?.province }}.</div>
                                        <div>{{ getClientData(this.bodyForm.userId)?.postal_code }}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="logo">
                            <div style="line-height: 32px">Ship To</div>
                            <div style="line-height: 32px; width: 12px; text-align: end">:</div>
                            <table>
                                <tr class="company-name">
                                    <td>
                                        {{ getClientData(this.bodyForm.userId)?.company }}
                                    </td>
                                </tr>
                                <tr class="company-Address">
                                    <td>
                                        <div>{{ getClientData(this.bodyForm.userId)?.street }}, {{ getClientData(this.bodyForm.userId)?.district }}</div>
                                        <div>{{ getClientData(this.bodyForm.userId)?.city }}, {{ getClientData(this.bodyForm.userId)?.province }}.</div>
                                        <div>{{ getClientData(this.bodyForm.userId)?.postal_code }}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="invoice-detail">
                        <div class="title">Sales Invoice</div>
                        <table>
                            <tr>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">Invoice Date</div>
                                        <div class="cell-content">{{ invoiceDetail?.createdAt ? formatLocalDate(invoiceDetail?.createdAt) : "Unavailable" }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">Invoice No</div>
                                        <div class="cell-content">{{ invoiceDetail?.invoice_number || "Unavailable" }}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">Terms</div>
                                        <div class="cell-content">{{ payment_method || "ALL" }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">FOB</div>
                                        <div class="cell-content"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">Ship Via</div>
                                        <div class="cell-content"></div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">Ship Date</div>
                                        <div class="cell-content">{{ dateNowID }}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">PO. No.</div>
                                        <div class="cell-content"></div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-content">
                                        <div class="cell-title">Currency</div>
                                        <div class="cell-content">RUPIAH</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="items" :class="invoiceChunks.length == 1 ? 'my-2' : ''">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Receipt Number</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Weight</th>
                                <th>Bag</th>
                                <th style="text-align: center">Price per kg</th>
                                <th style="text-align: center">Unit Price</th>
                                <th style="text-align: center">Additional Fee</th>
                                <th style="text-align: center">Discount</th>
                                <th style="text-align: center">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="i in getRowNums(index, invoiceChunks.length, receipts.length)" :key="i">
                                <td style="text-align: center">{{ chunk[i - 1]?.no ?? " " }}</td>
                                <td>{{ chunk[i - 1]?.receipt_number ?? " " }}</td>
                                <td>{{ chunk[i - 1]?.origin ?? " " }}</td>
                                <td>{{ chunk[i - 1]?.destination ?? " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.weight ?? " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.bag ?? " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.price_per_kg ? "Rp" + chunk[i - 1]?.price_per_kg.toLocaleString() : " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.unit_price ? "Rp" + chunk[i - 1]?.unit_price.toLocaleString() : " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.additional_fee ? "Rp" + chunk[i - 1]?.additional_fee.toLocaleString() : " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.discount ? "Rp" + chunk[i - 1]?.discount.toLocaleString() : " " }}</td>
                                <td style="text-align: end">{{ chunk[i - 1]?.amount ? "Rp" + chunk[i - 1]?.amount.toLocaleString() : " " }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="index + 1 === invoiceChunks.length" class="subtotal">
                    <div class="additional">
                        <div class="written">
                            <div>Say</div>
                            <div>:</div>
                            <div class="written-content">
                                {{ getWrittenNumber(invoiceSum.total) }}
                            </div>
                        </div>
                        <div class="description">
                            <div class="title">Description</div>
                            <div class="content">
                                <div>TRANSFER TO: BANK BCA</div>
                                <div>NO. REKENING: 032901777</div>
                                <div>A/N UNIVESERV FORTUNA CEMERLANG</div>
                            </div>
                        </div>
                    </div>
                    <div class="summary">
                        <div class="sub">
                            <div>Subtotal</div>
                            <div>:</div>
                            <div class="written-content">Rp {{ invoiceSum.subtotal.toLocaleString() }}</div>
                        </div>
                        <div class="tax">
                            <div>PPN 1.1%</div>
                            <div>:</div>
                            <div class="written-content">Rp {{ invoiceSum.tax.toLocaleString() }}</div>
                        </div>
                        <div class="sub">
                            <div>Discount</div>
                            <div>:</div>
                            <div class="written-content">Rp {{ invoiceSum.additional_discount ? invoiceSum.additional_discount.toLocaleString() : "-" }}</div>
                        </div>
                        <div class="total">
                            <div>Total</div>
                            <div>:</div>
                            <div class="written-content">Rp {{ invoiceSum.total.toLocaleString() }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="index + 1 === invoiceChunks.length" class="signatures">
                    <div class="delegation">
                        <div class="title">Prepared By</div>
                        <div class="date">Date:</div>
                    </div>
                    <div class="delegation">
                        <div class="title">Approved By</div>
                        <div class="date">Date:</div>
                    </div>
                    <div class="delegation">
                        <div class="title">Shipped By</div>
                        <div class="date">Date:</div>
                    </div>
                    <div class="delegation">
                        <div class="title">Received By</div>
                        <div class="date">Date:</div>
                    </div>
                </div>
                <div class="page-number mt-1" style="flex: 1; text-align: center">{{ index + 1 }} of {{ invoiceChunks.length }}</div>
            </div>
        </div>

        <div v-if="generateInvoiceProgress > 0" class="preview-invoice" :class="generateInvoiceProgress == 2 ? 'disabled' : ''">
            <div class="invoices-list">
                <div class="invoice" v-for="(chunk, index) in invoiceChunks" :key="index">
                    <div class="header" v-if="index === 0">
                        <div class="addresses">
                            <div class="logo mb-1">
                                <img class="icons" src="/usfc_monogram.svg" style="transform: scale(0.5); object-fit: contain" />
                                <table>
                                    <tr class="company-name">
                                        <td>PT UNIVESERV FORTUNA CEMERLANG</td>
                                    </tr>
                                    <tr class="company-Address">
                                        <td>
                                            <div>JL. BY PASS KM 7</div>
                                            <div>RT. 002 RW.001, KEL. PISANG</div>
                                            <div>KEC. PAUH, KOTA PADANG</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="logo">
                                <div style="line-height: 32px">Bill To</div>
                                <div style="line-height: 32px; width: 19px; text-align: end">:</div>
                                <table>
                                    <tr class="company-name">
                                        <td>
                                            {{ getClientData(this.bodyForm.userId)?.company }}
                                        </td>
                                    </tr>
                                    <tr class="company-Address">
                                        <td>
                                            <div>{{ getClientData(this.bodyForm.userId)?.street }}, {{ getClientData(this.bodyForm.userId)?.district }}</div>
                                            <div>{{ getClientData(this.bodyForm.userId)?.city }}, {{ getClientData(this.bodyForm.userId)?.province }}.</div>
                                            <div>{{ getClientData(this.bodyForm.userId)?.postal_code }}</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="logo">
                                <div style="line-height: 32px">Ship To</div>
                                <div style="line-height: 32px; width: 12px; text-align: end">:</div>
                                <table>
                                    <tr class="company-name">
                                        <td>
                                            {{ getClientData(this.bodyForm.userId)?.company }}
                                        </td>
                                    </tr>
                                    <tr class="company-Address">
                                        <td>
                                            <div>{{ getClientData(this.bodyForm.userId)?.street }}, {{ getClientData(this.bodyForm.userId)?.district }}</div>
                                            <div>{{ getClientData(this.bodyForm.userId)?.city }}, {{ getClientData(this.bodyForm.userId)?.province }}.</div>
                                            <div>{{ getClientData(this.bodyForm.userId)?.postal_code }}</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="invoice-detail">
                            <div class="title">Sales Invoice</div>
                            <table>
                                <tr>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">Invoice Date</div>
                                            <div class="cell-content">{{ invoiceDetail?.createdAt ? formatLocalDate(invoiceDetail?.createdAt) : "Unavailable" }}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">Invoice No</div>
                                            <div class="cell-content">{{ invoiceDetail?.invoice_number || "Unavailable" }}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">Terms</div>
                                            <div class="cell-content">{{ payment_method || "ALL" }}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">FOB</div>
                                            <div class="cell-content"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">Ship Via</div>
                                            <div class="cell-content"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">Ship Date</div>
                                            <div class="cell-content">{{ dateNowID }}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">PO. No.</div>
                                            <div class="cell-content"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-content">
                                            <div class="cell-title">Currency</div>
                                            <div class="cell-content">RUPIAH</div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="items mb-2 mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Receipt Number</th>
                                    <th>Origin</th>
                                    <th>Destination</th>
                                    <th>Weight</th>
                                    <th>Bag</th>
                                    <th style="text-align: center">Price per kg</th>
                                    <th style="text-align: center">Unit Price</th>
                                    <th style="text-align: center">Additional Fee</th>
                                    <th style="text-align: center">Discount</th>
                                    <th style="text-align: center">Amount</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(i, index) in getRowNums(index, invoiceChunks.length, receipts.length)" :key="index">
                                    <td style="text-align: center">{{ chunk[i - 1]?.no ?? " " }}</td>
                                    <td>{{ chunk[i - 1]?.receipt_number ?? " " }}</td>
                                    <td>{{ chunk[i - 1]?.origin ?? " " }}</td>
                                    <td>{{ chunk[i - 1]?.destination ?? " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.weight ?? " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.bag ?? " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.price_per_kg ? "Rp" + chunk[i - 1]?.price_per_kg.toLocaleString() : " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.unit_price ? "Rp" + chunk[i - 1]?.unit_price.toLocaleString() : " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.additional_fee ? "Rp" + chunk[i - 1]?.additional_fee.toLocaleString() : " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.discount ? "Rp" + chunk[i - 1]?.discount.toLocaleString() : " " }}</td>
                                    <td style="text-align: end">{{ chunk[i - 1]?.amount ? "Rp" + chunk[i - 1]?.amount.toLocaleString() : " " }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="index + 1 === invoiceChunks.length" class="subtotal">
                        <div class="additional">
                            <div class="written">
                                <div>Say</div>
                                <div>:</div>
                                <div class="written-content">
                                    {{ getWrittenNumber(invoiceSum.total) }}
                                </div>
                            </div>
                            <div class="description">
                                <div class="title">Description</div>
                                <div class="content">
                                    <div>TRANSFER TO: BANK BCA</div>
                                    <div>NO. REKENING: 032901777</div>
                                    <div>A/N UNIVESERV FORTUNA CEMERLANG</div>
                                </div>
                            </div>
                        </div>
                        <div class="summary">
                            <div class="sub">
                                <div>Subtotal</div>
                                <div>:</div>
                                <div class="written-content">Rp {{ invoiceSum.subtotal.toLocaleString() }}</div>
                            </div>
                            <div class="tax">
                                <div>PPN 1.1%</div>
                                <div>:</div>
                                <div class="written-content">Rp {{ invoiceSum.tax.toLocaleString() }}</div>
                            </div>
                            <div class="sub">
                                <div>Discount</div>
                                <div>:</div>
                                <div class="written-content">Rp {{ invoiceSum.additional_discount ? invoiceSum.additional_discount.toLocaleString() : "-" }}</div>
                            </div>
                            <div class="total">
                                <div>Total</div>
                                <div>:</div>
                                <div class="written-content">Rp {{ invoiceSum.total.toLocaleString() }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="index + 1 === invoiceChunks.length" class="signatures">
                        <div class="delegation">
                            <div class="title">Prepared By</div>
                            <div class="date">Date:</div>
                        </div>
                        <div class="delegation">
                            <div class="title">Approved By</div>
                            <div class="date">Date:</div>
                        </div>
                        <div class="delegation">
                            <div class="title">Shipped By</div>
                            <div class="date">Date:</div>
                        </div>
                        <div class="delegation">
                            <div class="title">Received By</div>
                            <div class="date">Date:</div>
                        </div>
                    </div>
                    <div class="page-number mt-1 pt-1" style="flex: 1; text-align: center">{{ index + 1 }} of {{ invoiceChunks.length }}</div>
                </div>
            </div>
            <div class="preview-detail">
                <div class="title">Invoice Preview</div>
                <div class="content">
                    <div class="content-title">Client</div>
                    <div class="content-desc">{{ getNameById(bodyForm.userId) }}</div>
                </div>
                <div class="content">
                    <div class="content-title">Invoice Number</div>
                    <div class="content-desc">{{ invoiceDetail?.invoice_number || "Unavailable" }}</div>
                </div>
                <div class="content">
                    <div class="content-title">Total Pages</div>
                    <div class="content-desc">{{ invoiceChunks?.length > 1 ? invoiceChunks.length + " Pages" : "1 Page" }}</div>
                </div>
                <div class="content" style="border-bottom: 1px solid rgba(0, 0, 0, 0.25)">
                    <div class="content-title">Created At</div>
                    <div class="content-desc">{{ invoiceDetail?.createdAt ? formatLocalDate(invoiceDetail?.createdAt) : "Unavailable" }}</div>
                </div>
                <div class="label-float pt-0 mx-2">
                    <div class="label-float mx-2 w-100">
                        <input id="additional_discount" placeholder=" " type="number" v-model="bodyForm.additional_discount" autocomplete="off" @input="validateDiscount()" />
                        <label>Additional Discount</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col px-3 mx-1"><button :disabled="!bodyForm.additional_discount && typeof bodyForm.additional_discount !== 'number'" @click="applyDiscount()" class="mx-2 btn btn-usfc-primary w-100">Apply Discount</button></div>
                </div>
                <div class="actions">
                    <div @click="generateInvoice()" class="btn btn-usfc-outline"><img class="blue-filter" style="transform: scale(0.75)" src="/icons/all/twotone/document-download.svg" /> Download Invoice</div>
                </div>
            </div>

            <div>
                <span @click="generateInvoiceProgress = 0" id="closeModal"><img class="d-flex justify-content-center" src="/icons/all/outline/add.svg" /></span>
            </div>
        </div>
    </div>
</template>
<script>
    import numberToWords from "number-to-words";
    import moment from "moment";
    import axios from "axios";
    import Datepicker from "@vuepic/vue-datepicker";
    import _ from "lodash";
    // import jsPDF from "jspdf";
    import "jspdf-autotable";
    import html2pdf from "html2pdf.js";

    export default {
        components: {
            Datepicker,
        },
        data() {
            return {
                selectedReceipts: [],
                invoiceDetail: null,
                bodyForm: {
                    start_date: null,
                    end_date: null,
                    userId: null,
                    additional_discount: null,
                },
                payment_method: "",
                chartData: { total: null },
                DoughnutChartData: {
                    labels: [],
                    datasets: [{ data: [], backgroundColor: ["#0abc06", "#f6931f", "#f25b5b"] }],
                },
                chartOptions: {
                    responsive: true,
                },
                receipts: [],
                arrayChunks: null,
                invoiceChunks: null,
                selectedClient: "",
                isPrinting: false,
                generateInvoiceProgress: 0,
            };
        },
        computed: {
            isAllSelected() {
                return JSON.stringify(this.receipts) === JSON.stringify(this.selectedReceipts);
            },
            dateNowID() {
                return moment().locale("id").format("D MMM YYYY");
            },
            dataExists() {
                return this.DoughnutChartData?.datasets[0]?.data?.length > 0;
            },
            allClient() {
                return this.$store.state.app.managerForm.allClients;
            },
            dateNow() {
                return new Date();
            },
            selectedDateRange() {
                return moment(this.bodyForm.start_date).format("DD/MM/YYYY") + " - " + moment(this.bodyForm.end_date).format("DD/MM/YYYY");
            },
            invoiceLength() {
                if (this.invoiceChunks.length > 1) return 34;
                return 36;
            },
            invoiceSum() {
                let subtotal = 0;
                for (let i = 0; i < this.invoiceChunks.length; i++) {
                    for (let j = 0; j < this.invoiceChunks[i].length; j++) {
                        subtotal += this.invoiceChunks[i][j].amount;
                    }
                }
                const additional_discount = this.invoiceDetail.additional_discount || 0;

                // SET TAX TO ZERO TEMP 31 AUG, ORIGINAL 0.011
                const tax = subtotal * 0;

                const total = subtotal + tax - additional_discount;
                return { subtotal, tax, total, additional_discount };
            },
        },
        methods: {
            selectAll() {
                if (!this.isAllSelected) return (this.selectedReceipts = _.cloneDeep(this.receipts));
                else this.selectedReceipts = [];
            },
            selectReceipt(item) {
                const index = this.findReceipt(item) - 1;
                if (index !== -1) {
                    this.selectedReceipts.splice(index, 1);
                    return;
                }
                this.selectedReceipts.push(item);
                return; // Item not found
            },
            findReceipt(item) {
                return this.selectedReceipts.findIndex((receipt) => receipt.receipt_number === item.receipt_number) + 1;
            },
            goTo(path, item) {
                if (this.selectedReceipts.length) return this.selectReceipt(item);
                window.open(path);
            },
            applyDiscount() {
                const token = JSON.parse(localStorage.getItem("token"));
                this.edit = true;
                axios
                    .patch(this.$store.state.app.apiBasepath + "/report/additional-discount/" + this.invoiceDetail.id, { additional_discount: this.bodyForm.additional_discount }, { headers: { Authorization: `Bearer ${token}` } })
                    .then(() => {
                        this.$alertSys("Discount Applied!", "success");
                        this.edit = false;
                        this.invoiceDetail.additional_discount = this.bodyForm.additional_discount;
                    })
                    .catch(() => {
                        this.edit = false;
                        this.$alertSys("An error occured", "err");
                    });
            },
            validateDiscount() {
                if (this.bodyForm.additional_discount < 0) this.bodyForm.additional_discount = 0;
                if (this.bodyForm.additional_discount > this.invoiceSum.subtotal + this.invoiceSum.tax) this.bodyForm.additional_discount = this.invoiceSum.subtotal + this.invoiceSum.tax;
                this.bodyForm.additional_discount = Number(this.bodyForm.additional_discount.toString().replace(/^0+/, ""));
            },
            formatLocalDate(dateItem) {
                return moment(dateItem).locale("id").format("D MMM YYYY");
            },
            getRowNums(index, arrlen, totalItems) {
                if (totalItems > 33) {
                    if (index === 0 || index + 1 === arrlen) {
                        return 44;
                    } else {
                        return 53;
                    }
                } else {
                    return 33;
                }
            },
            getWrittenNumber(num) {
                return numberToWords.toWords(num);
            },
            splitArrayIntoChunks(chunkSize) {
                const array = _.cloneDeep(this.selectedReceipts);
                const chunks = [];
                let index = 0;

                while (index < array.length) {
                    chunks.push(array.slice(index, index + chunkSize));
                    index += chunkSize;
                }

                return chunks;
            },
            invoiceChunksSplitter() {
                const array = _.cloneDeep(this.selectedReceipts);
                const chunks = [];
                const arrayLength = array.length;

                if (arrayLength > 33) {
                    // CEK JIKA DATA LEBIH DARI 33
                    chunks.push(array.slice(0, 44)); // SIMPAN 44 KE INDEX 1

                    let remainingData = array.slice(44); // TENTUKAN SISA DATA, PALING SEDIKIT 44

                    while (remainingData.length > 0) {
                        console.log(remainingData.length, "CEK");
                        // CEK JIKA SISA DATA LEBIH DARI 0
                        if (remainingData.length > 44) {
                            // CEK JIKA SISA DATA LEBIH DARI 44
                            if (remainingData.length >= 53) {
                                //CEK JIKA SISA DATA LEBIH DARI 53
                                chunks.push(remainingData.slice(0, 53)); // JIKA YA, SIMPAN 53
                                remainingData = remainingData.slice(53); // TENTUKAN SISA DATA, MINIMAL 53
                            } else {
                                chunks.push(remainingData);
                                remainingData = [];
                                break;
                            }
                        } else {
                            chunks.push(remainingData);
                            remainingData = [];
                        }
                    }

                    // if (chunks[chunks.length - 1].length > 45 && chunks[chunks.length - 1].length < 53) {
                    //     chunks.push([]);
                    // }
                } else {
                    chunks.push(array);
                }

                return chunks;
            },
            setSelectedClient(name) {
                this.selectedClient = name;
                console.log(this.selectedClient);
            },

            generatePDF() {
                document.body.style.overflow = "hidden";
                const self = this;
                self.isPrinting = true;

                setTimeout(() => {
                    const element = document.getElementById("print-table");
                    const options = {
                        pagesplit: true,
                        margin: [10, 10, 10, 10], // Adjust the margin values as needed
                        pagebreak: { avoid: ".limiter", mode: "css" },
                        filename: "table.pdf",
                        jsPDF: {
                            unit: "mm",
                            format: "a4",
                            orientation: "landscape",
                        },
                        html2canvas: {
                            dpi: 192,
                            scale: 4,
                            letterRendering: true,
                            useCORS: true,
                        },
                    };
                    html2pdf()
                        .from(element)
                        .set(options)
                        .toPdf()
                        .get("pdf")
                        .then(function (pdf) {
                            self.isPrinting = false;
                            document.body.style.overflow = "auto";

                            // Convert the PDF to a blob object
                            const blob = pdf.output("blob");

                            // Create a blob URL
                            const blobUrl = URL.createObjectURL(blob);

                            // Open a new window with the PDF
                            const printWindow = window.open(blobUrl, "_blank");

                            // Wait for the print window to load
                            printWindow.onload = function () {
                                // Trigger the print dialog
                                printWindow.print();

                                // Clean up the resources
                                printWindow.onafterprint = function () {
                                    printWindow.close();
                                    URL.revokeObjectURL(blobUrl);
                                };
                            };
                        });
                }, 150);
            },
            showPreview() {
                this.generateInvoiceProgress = 1;

                let token = JSON.parse(localStorage.getItem("token"));
                function sortByBookingReceiptId(data) {
                    return data.sort((a, b) => a.bookingReceiptId - b.bookingReceiptId);
                }
                function sortAndGetBookingReceiptIds(data) {
                    // Sort the array of objects by bookingReceiptId
                    const sortedData = data.sort((a, b) => a.bookingReceiptId - b.bookingReceiptId);

                    // Map the sorted array to extract and return an array of strings of bookingReceiptIds
                    return sortedData.map((item) => String(item.bookingReceiptId));
                }
                const bookingIds = sortAndGetBookingReceiptIds(this.selectedReceipts).join(",");

                this.selectedReceipts = sortByBookingReceiptId(this.selectedReceipts);
                this.selectedReceipts.forEach((element, index) => {
                    element.no = index + 1;
                });
                axios
                    .post(
                        `${this.$store.state.app.apiBasepath}/report/invoice-number/${this.bodyForm.userId}?start_date=${moment(this.bodyForm.start_date).format("YYYY-MM-DD")}&end_date=${moment(this.bodyForm.end_date).format("YYYY-MM-DD")}&payment_method=${this.payment_method}`,
                        {
                            booking_receipt_id: bookingIds,
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then((res) => {
                        console.log(res, "ressss");
                        this.invoiceDetail = res?.data?.data;

                        this.arrayChunks = this.splitArrayIntoChunks(20);

                        if (this.selectedReceipts.length > 33) {
                            this.invoiceChunks = this.invoiceChunksSplitter();
                        } else {
                            this.invoiceChunks = [this.selectedReceipts];
                        }
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            generateInvoice() {
                document.body.style.overflow = "hidden";
                this.generateInvoiceProgress = 2;
                const self = this;

                setTimeout(() => {
                    const element = document.getElementById("print-invoice");
                    const options = {
                        pagesplit: true,
                        margin: [3, 3, 3, 3], // Adjust the margin values as needed
                        pagebreak: { avoid: [".invoice"], after: [".invoice"], mode: "css" },
                        filename: "table.pdf",
                        jsPDF: {
                            unit: "mm",
                            format: "a4",
                            orientation: "portrait",
                        },
                        html2canvas: {
                            dpi: 192,
                            scale: 4,
                            letterRendering: true,
                            useCORS: true,
                        },
                    };
                    html2pdf()
                        .from(element)
                        .set(options)
                        .toPdf()
                        .get("pdf")
                        .then(function () {
                            // var pageCount = pdf.internal.getNumberOfPages();
                            // pdf.deletePage(pageCount);
                            document.body.style.overflow = "auto";
                            self.generateInvoiceProgress = 1;
                        })
                        .save();
                }, 150);
            },

            textLimiter(string) {
                console.log(string);
                if (string.length > 15) {
                    return string.substring(0, 15) + "...";
                }
                return string;
            },
            exportExcel() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(`${this.$store.state.app.apiBasepath}/report/gas-location-export/${this.bodyForm.userId}?start_date=${moment(this.bodyForm.start_date).format("YYYY-MM-DD")}&end_date=${moment(this.bodyForm.end_date).format("YYYY-MM-DD")}&payment_method=${this.payment_method}`, {
                        headers: { Authorization: `Bearer ${token}` },
                        responseType: "blob", // Set the response type to 'blob'
                    })
                    .then((res) => {
                        const defaultFileName = `Gasoline Report_${this.getNameById(this.bodyForm.userId)}${this.payment_method ? "_(" + this.payment_method + ")" : "_(ALL)"}_${moment(this.bodyForm.start_date).format("DD-MM-YYYY")}_${moment(this.bodyForm.end_date).format("DD-MM-YYYY")}`;

                        // Create a temporary link element
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));

                        let fileName = defaultFileName;

                        // Set the download attribute with the filename
                        link.setAttribute("download", fileName);

                        // Append the link to the document body and click it programmatically
                        document.body.appendChild(link);
                        link.click();

                        // Clean up the temporary link element
                        document.body.removeChild(link);
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },

            submit() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(`${this.$store.state.app.apiBasepath}/report/gas-location-report/${this.bodyForm.userId}?start_date=${moment(this.bodyForm.start_date).format("YYYY-MM-DD")}&end_date=${moment(this.bodyForm.end_date).format("YYYY-MM-DD")}&payment_method=${this.payment_method}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((res) => {
                        this.receipts = [];
                        // for (var i = 0; i < 17; i++) {
                        //     this.receipts = this.receipts.concat(res.data.data);
                        // }
                        this.receipts = res.data.data;
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            getAllClients() {
                let token = JSON.parse(localStorage.getItem("token"));

                const x = this.$store.state.userData.access_level;
                if (x >= 4) {
                    axios
                        .get(this.$store.state.app.apiBasepath + "/user/all-company", { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            console.log(res);
                            this.$store.state.app.managerForm.allClients = res.data.rows;
                            this.ascSort();
                            console.log(this.allClient);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else if (x === 3) {
                    axios
                        .get(this.$store.state.app.apiBasepath + "/user/sales-details/" + this.$store.state.userData.id, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            console.log("200: " + res);
                            this.$store.state.app.managerForm.allClients = res.data.client;
                            this.ascSort();
                            console.log(this.allClient);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else if (x === 2) {
                    this.bodyForm.userId = this.$store.state.userData.corporateId;
                }
            },
            ascSort() {
                function compare(a, b) {
                    if (a.company < b.company) return -1;
                    if (a.company > b.company) return 1;
                }

                this.$store.state.app.managerForm.allClients.sort(compare);
            },
            getNameById(targetId) {
                for (var i = 0; i < this.allClient.length; i++) {
                    if (this.allClient[i].id === targetId) {
                        return this.allClient[i].company;
                    }
                }
                return null;
            },
            getClientData(targetId) {
                for (var i = 0; i < this.allClient.length; i++) {
                    if (this.allClient[i].id === targetId) {
                        return this.allClient[i];
                    }
                }
                return null;
            },
        },

        mounted() {
            this.getAllClients();
        },
    };
</script>
<style lang="scss" scoped>
    .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        border: 1px solid rgba($color: #3699ff, $alpha: 0.5);
        cursor: pointer;

        &:hover {
            background-color: #3699ff;
            opacity: 0.5;
        }

        &.selected {
            background-color: #3699ff;
            opacity: 1;
        }
    }
    @keyframes box-shadow-transition {
        0% {
            box-shadow: 0 0 100px 15px rgba(54, 153, 255, 0);
        }
        20% {
            box-shadow: 0 0 50px 15px rgba(54, 153, 255, 0.6);
        }
        80% {
            box-shadow: 0 0 50px 15px rgba(54, 153, 255, 0.6);
        }
        100% {
            box-shadow: 0 0 0 rgba(54, 153, 255, 0);
        }
    }

    .disabled {
        opacity: 1;
        transform: scale(0.95);
        filter: brightness(70%);
        user-select: none;
        pointer-events: none;
    }

    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    .animated-box {
        animation: box-shadow-transition 4s infinite;
    }

    .preview-invoice {
        transition: all 0.3s ease;
        padding: 25px;
        width: 90vw;
        height: 90vh;
        background: white;

        display: flex;
        position: fixed;
        left: 5vw;
        top: 5vh;

        z-index: 99999;

        box-shadow: 0 0 50px 15px rgba($color: #000, $alpha: 0.15);

        border-radius: 12px;

        .preview-detail {
            padding-left: 12px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                font-size: 24px;
                font-weight: 600;

                margin-bottom: 22px;
            }

            .content {
                .content-title {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                }
            }

            .actions {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-top: 22px;
            }
        }

        .invoices-list {
            background: rgba($color: #000, $alpha: 0.15);
            overflow-y: auto;
            padding: 22px;

            display: flex;
            flex-direction: column;
            gap: 22px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #3699ff;
                border-radius: 6px;
            }
            .invoice {
                outline: 10px solid white;
                // margin-top: 22px;
                width: 720px;
                // height: 877px;
            }
        }
    }
    .invoice {
        outline: 0px solid white;
        font-size: 11px;
        border: 1px solid rgba($color: #000000, $alpha: 1);
        display: flex;
        flex-direction: column;
        background: white;
        width: auto;
        // height: 877px;
        padding: 10px;
        margin-top: 10px;
        // justify-content: space-between;

        .signatures {
            display: flex;
            width: 50%;
            justify-content: space-between;
            .delegation {
                display: flex;
                flex-direction: column;

                .title {
                    padding-top: 6px;
                    padding-bottom: 36px;
                    padding-right: 6px;
                    flex-grow: 1;
                }
                .date {
                    border-top: 1px solid black;
                }
            }
        }

        .subtotal {
            display: flex;
            gap: 12px;
            .additional {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 3px;
                .written {
                    display: flex;
                    min-height: 14px;

                    .written-content {
                        border: 1px solid black;
                        flex-grow: 1;
                        min-height: 64px;
                        padding: 3px;
                    }
                    div {
                        min-width: 15px;
                    }
                }

                .description {
                    margin-top: 3px;
                    flex-grow: 1;
                    border: 1px solid black;
                    padding: 3px;

                    .title {
                        position: absolute;

                        background: white;
                        padding: 0 3px;
                        transform: translate(5px, -10px);
                    }

                    .content {
                        font-size: 12px;
                    }
                }
            }

            .summary {
                font-size: 12px;
                font-weight: 600;
                width: 35%;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .sub {
                    padding: 3px;
                    display: flex;
                    border: 1px solid black;

                    div {
                        min-width: 50px;

                        &:last-child {
                            flex-grow: 1;
                            text-align: end;
                        }
                    }
                }
                .tax {
                    padding: 3px;
                    display: flex;
                    border: 1px solid black;

                    div {
                        min-width: 50px;

                        &:last-child {
                            flex-grow: 1;
                            text-align: end;
                        }
                    }
                }
                .total {
                    color: rgba($color: #3699ff, $alpha: 1);
                    font-size: 16px;
                    padding: 3px;
                    display: flex;
                    border: 1px solid black;

                    div {
                        min-width: 25px;

                        &:nth-child(2) {
                            text-align: center;
                        }

                        &:last-child {
                            flex-grow: 1;
                            text-align: end;
                        }
                    }
                }
            }
        }
        .items {
            table {
                border: 1px solid rgba($color: #000000, $alpha: 0.1);
                border-collapse: collapse;
                width: 100%;

                tr {
                    &:last-child {
                        border-bottom: 1px solid black;
                    }
                }

                th {
                    border: 1px solid black;
                }
                td {
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    height: 18.5px;
                    padding: 1px 2px;
                }

                thead {
                    background: rgba($color: #3699ff, $alpha: 0.5);
                }
            }
        }

        .header {
            // flex: 1;
            display: flex;
            width: 100% !important;

            .addresses {
                flex-grow: 1;

                table {
                    margin: 12px;
                    border-collapse: collapse;
                    flex-grow: 1;

                    border: 1px solid rgba($color: #000000, $alpha: 0.2);

                    td {
                        padding: 2px;
                    }
                }

                .logo {
                    max-height: 54px;
                    display: flex;
                    line-height: 11px;
                }

                .company-name {
                    font-weight: 600;

                    border-bottom: 1px dashed black;
                }
            }
            .invoice-detail {
                .title {
                    text-align: end;
                    font-size: 26px;
                    font-weight: 600;
                }

                table {
                    border-collapse: collapse;
                    border: 1px solid rgba($color: #000000, $alpha: 0.2);
                    table-layout: fixed;

                    td {
                        border: 1px dashed black;
                        padding: 0 2px;
                        height: 16px;
                        .col-content {
                            display: flex;
                            flex-direction: column;
                            min-width: 120px !important;

                            div {
                                min-height: 14px;
                            }

                            .cell-content {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn.btn-usfc-outline {
        transition: all 0.3s ease;
        color: #3699ff;
        background: white;
        border: 1px solid #3699ff;
        border-radius: 6px;
        padding: 10px 25px;
        font-weight: 600;

        .blue-filter {
            filter: invert(43%) sepia(96%) saturate(720%) hue-rotate(185deg) brightness(90%) contrast(101%);
        }

        &:hover {
            color: #3699ff;
            border: 1px solid #3699ff;
            background: rgba($color: #3699ff, $alpha: 0.15);
        }
    }
    abbr[title] {
        text-decoration: none;
    }
    .table-wrapper {
        height: 50vh;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }

        table {
            width: 100%;

            thead {
                tr {
                    th {
                        padding: 0 6px;
                        position: sticky;
                        top: 0;
                        background: #fff;
                        overflow: hidden;
                    }
                }
            }

            tbody {
                tr {
                    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #3699ff, $alpha: 0.15);
                    }
                    td {
                        padding: 12px 6px;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .print-wrapper {
        // position: relative;
        // top: 150px;
        font-size: 12px;
        // height: 50vh;
        // overflow-y: auto;
        // width: 100%;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }

        table {
            width: 100%;

            thead {
                tr {
                    th {
                        padding: 12px;
                        font-size: 14px;
                        font-weight: 600;
                        position: sticky;
                        top: 0;
                        background: #fff;
                        overflow: hidden;
                    }
                }
            }

            tbody {
                font-size: 6px;
                tr {
                    td {
                        padding: 6px 12px;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    #chart {
        max-width: 400px;
        max-height: 400px;
    }
    .dp__main + .option-selected {
        transform: translateX(15px) translateY(-22px) !important;
    }
    .label-float {
        label {
            font-size: 12px !important;
            z-index: 99;
            transform: translateX(0px) translateY(-15px) !important;
        }

        &:has(.dp__main) {
            padding-top: 0;
        }
    }
    .report-container {
        height: 87vh;
        display: block;
        background: transparent;

        .report-filter {
            max-height: 20vh;
            width: 100%;
            .dp__main {
                padding: 15px;
                input {
                    background-color: whitesmoke;
                    padding: 15px 20px;
                    height: 100%;
                    border: none;
                }

                .dp__input_icon {
                    left: auto !important;
                    right: 0 !important;
                }
            }
        }

        .report-content {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 22px;

            .title {
                /* Others/Soft-Black */

                color: #777777;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                padding: 15px 0;

                display: flex;
                justify-content: space-between;
                .export {
                    display: flex;
                    justify-content: flex-end;
                    gap: 6px;

                    margin: 12px;
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .grades {
                    display: flex;
                    flex-direction: column;
                    width: 125px;

                    .grade {
                        text-align: start;
                        justify-content: space-between;
                        display: flex;

                        div {
                            flex-grow: 1;
                            max-width: 2em;
                        }

                        .dot {
                            &_a {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #0abc06;
                            }
                            &_b {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #f6931f;
                            }
                            &_c {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #f25b5b;
                            }
                        }
                    }
                }

                .chart {
                    flex-grow: 1;
                    justify-content: center;
                    justify-items: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
            }
        }
    }

    .label-float input {
        width: 100%;
    }

    .label-float input + label {
        border: none;
        // background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px !important;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: calc(50% - 5px) !important;
        left: 0 !important;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 11px !important;
        top: 15px !important;
        left: -5px !important;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }
</style>
