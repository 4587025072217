<template>
    <div style="max-height: 90vh; padding-bottom: 12%">
        <div id="modalTitle" class="row">
            <div class="col p-2">
                <h5>
                    <span class="p-2" style="font-weight: 700; float: left">Create Recipient</span>
                    <span @click="this.$store.state.app.openModal = ''" id="closeModal"><img class="d-flex justify-content-center" src="/icons/all/outline/add.svg" /></span>
                </h5>
            </div>
        </div>

        <form-wrapper>
            <div id="forms" class="row text-start d-flex flex-nowrap profile mb-3" style="max-height: 75vh; overflow-y: scroll">
                <form>
                    <div class="row mt-3">
                        <div class="col">
                            <div style="font-weight: 600">1. PIC Details</div>
                        </div>
                    </div>
                    <div v-if="!$store.state.userData.public_user" class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.company" id="company" type="text" placeholder=" " autocomplete="off" />
                        <label>Company</label>
                    </div>
                    <div class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.pic_name" id="pic_name" type="text" placeholder=" " autocomplete="off" />
                        <label>PIC Name</label>
                    </div>
                    <div class="label-float">
                        <input @input="validateStage(0)" v-model="bodyForm.phone" id="phone" type="tel" placeholder=" " autocomplete="off" />
                        <label>Phone Number</label>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div style="font-weight: 600">2. Address Details</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="label-float">
                                <input @blur="clearSuggestion()" @input="debouncedOnChange" v-model="gmaps.searchQuery" id="address" type="text" placeholder=" " autocomplete="off" />
                                <label>Find Address</label>

                                <div @click="requestGeo()" style="cursor: pointer"><img style="position: absolute; top: 30px; right: 10px" src="@/assets/gps.svg" /></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="gmaps.suggestions.length > 0" id="suggestions" class="row p-1 bg-white">
                        <div class="col p-0">
                            <div @click="selectLocation({ lat: suggestion.geometry.lat(), lng: suggestion.geometry.lng() })" v-for="suggestion in gmaps.suggestions" :key="suggestion.place_id" class="row py-1 px-0 mx-0 suggestion">
                                <div class="col-1 d-flex"><img class="m-auto" src="@/assets/location.svg" /></div>
                                <div class="col">
                                    <div class="row" style="font-weight: 600">
                                        {{ suggestion.name }}
                                    </div>
                                    <div class="row" style="font-size: 12px">
                                        {{ suggestion.formatted_address }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <!-- KEY AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM -->
                            <GoogleMap
                                api-key="AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM"
                                @click="markerCallback($event.latLng)"
                                style="width: 100%; height: 200px; filter: saturate(2)"
                                :center="gmaps.center"
                                :controlSize="24"
                                :fullscreen-control="false"
                                :clickableIcons="false"
                                :map-type-control="false"
                                :street-view-control="false"
                                :zoom="gmaps.zoom">
                                <Marker v-if="this.gmaps.marker.lat != null" :options="{ position: gmaps.marker, draggable: true, icon: '/icons/location.svg' }" @dragend="markerCallback($event.latLng)" />
                            </GoogleMap>
                        </div>
                    </div>

                    <div class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.province" id="province" type="text" placeholder=" " autocomplete="off" />
                        <label>Province</label>
                    </div>
                    <div class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.city" id="city" type="text" placeholder=" " autocomplete="off" />
                        <label>City</label>
                    </div>
                    <div class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.district" id="district" type="text" placeholder=" " autocomplete="off" />
                        <label>District</label>
                    </div>
                    <div class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.street" id="street" type="text" placeholder=" " autocomplete="off" />
                        <label>Street</label>
                    </div>
                    <div class="label-float">
                        <input @input="validateStage()" v-model="bodyForm.postal_code" id="postal_code" type="text" placeholder=" " autocomplete="off" />
                        <label>Postal Code</label>
                    </div>
                </form>
            </div>
        </form-wrapper>

        <div id="navigation" class="row my-2">
            <div class="col">
                <button @click="register()" disabled id="finalize" style="float: right" class="mx-2 btn btn-usfc-primary">Create Recipient</button>

                <span @click="this.$store.state.app.openModal = ''" style="float: right" class="mx-2 btn btn-cancel"> Cancel</span>
            </div>
        </div>
    </div>
</template>

<script>
    import "jquery/dist/jquery.js";

    import { Loader } from "@googlemaps/js-api-loader";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";
    import "bootstrap-icons/font/bootstrap-icons.css";
    import $ from "jquery";
    import _ from "lodash";
    import axios from "axios";
    import { GoogleMap, Marker } from "vue3-google-map";

    export default {
        name: "originCreate",
        components: {
            GoogleMap,
            Marker,
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
            userFullName() {
                if (this.$store.state.userData.full_name.length > 30) {
                    return this.$store.state.userData.full_name.substring(0, 28) + "...";
                } else {
                    return this.$store.state.app.userData.full_name;
                }
            },
            role() {
                if (this.$store.state.userData.access_level == 5) {
                    return "Manager";
                } else if (this.$store.state.userData.access_level == 4) {
                    return "Admin";
                } else if (this.$store.state.userData.access_level == 3) {
                    return "Sales";
                } else if (this.$store.state.userData.access_level == 2) {
                    return "Client";
                } else {
                    return "Transporter";
                }
            },
        },
        data() {
            return {
                gmaps: {
                    zoom: 4,
                    searchQuery: "",
                    center: { lat: 0.4484759, lng: 113.944888 },
                    options: {
                        panControl: false,
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: true,
                        streetViewControl: false,
                        overviewMapControl: false,
                        rotateControl: false,
                    },
                    markerOptions: {
                        icon: "/icons/location.svg",
                    },
                    suggestions: [],
                    marker: {
                        lat: null,
                        lng: null,
                    },
                    selected: {
                        area: null,
                        formatted_address: "",
                        coords: {
                            lat: null,
                            lng: null,
                        },
                    },
                },

                photo64: "",
                photoFile: "",
                access_level: 4,
                stageIndex: 0,
                pwdconf: "",
                stages: [
                    // status (0: upcoming, 1: active, 2: done)
                    {
                        name: "Fill Personal Profile",
                        status: 1,
                    },
                    {
                        name: "Create Password",
                        status: 0,
                    },
                ],
                selects: "",
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
                bodyForm: {
                    city: "",
                    province: "",
                },
            };
        },
        methods: {
            requestGeo() {
                var options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                };
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoFailure, options);
                } else {
                    console.log("No support");
                }
            },

            geoSuccess(position) {
                console.log("Success");

                console.log(position.coords);
                let coords = {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                };

                this.selectLocation(coords);
            },

            geoFailure(error) {
                console.log("Failure");
                console.log(error);
            },

            async getLocDetails(coords) {
                try {
                    const loader = new Loader({
                        apiKey: "AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM",
                        version: "weekly",
                        token: this.$store.state.app.sessionToken,
                    });

                    const google = await loader.load();

                    const geocoder = new google.maps.Geocoder();

                    // Geocode the coordinates to get place details
                    geocoder.geocode({ location: coords }, async (results, status) => {
                        if (status === "OK" && results[0]) {
                            const placeId = results[0].place_id;

                            // Fetch place details using place_id
                            const placesService = new google.maps.places.PlacesService(document.createElement("div"));
                            placesService.getDetails({ placeId }, (place, status) => {
                                if (status === "OK") {
                                    console.log(place);

                                    // Update this.bodyForm with address components
                                    const details = results[0];
                                    const comps = details.address_components;
                                    const province = comps.find((component) => component.types.includes("administrative_area_level_1"));
                                    const city = comps.find((component) => component.types.includes("administrative_area_level_2"));
                                    const district = comps.find((component) => component.types.includes("administrative_area_level_3"));
                                    const postal_code = comps.find((component) => component.types.includes("postal_code"));
                                    const street = comps.find((component) => component.types.includes("route")) || comps.find((component) => component.types.includes("administrative_area_level_4"));
                                    this.bodyForm.province = province?.long_name;
                                    this.bodyForm.city = city?.long_name;
                                    this.bodyForm.district = district?.long_name;
                                    this.bodyForm.street = street?.long_name;
                                    this.bodyForm.postal_code = postal_code?.long_name;
                                    this.bodyForm.latitude = details.geometry.location.lat();
                                    this.bodyForm.longitude = details.geometry.location.lng();

                                    // Update this.gmaps.selected.coords
                                    this.gmaps.selected.coords = {
                                        lat: details.geometry.location.lat(),
                                        lng: details.geometry.location.lng(),
                                    };

                                    // Enable the finalize button
                                    $("#finalize").prop("disabled", false);
                                } else {
                                    console.error("Places Service failed due to: " + status);
                                }
                            });
                        } else {
                            console.error("Geocoder failed due to: " + status);
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            },

            clearSuggestion() {
                setTimeout(() => {
                    this.gmaps.suggestions = [];
                }, 150);
            },
            selectLocation(itemIndex) {
                console.log(itemIndex, itemIndex.lat);
                let loc = itemIndex.location;
                if (itemIndex.lat) loc = itemIndex;
                this.gmaps.marker = { lat: loc.lat, lng: loc.lng };
                this.gmaps.center = { lat: loc.lat, lng: loc.lng };
                setTimeout(() => {
                    this.gmaps.suggestions = [];
                    this.gmaps.zoom = 15;
                }, 250);
                this.getLocDetails(loc);
            },
            async suggestionResult() {
                try {
                    const loader = new Loader({
                        apiKey: "AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM", // Replace with your actual Google Maps API key
                        version: "weekly",
                        token: this.$store.state.app.sessionToken,
                    });

                    const google = await loader.load();

                    const placesService = new google.maps.places.PlacesService(document.createElement("div"));

                    placesService.textSearch({ query: this.gmaps.searchQuery, location: this.gmaps.center }, (results, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                            const suggestions = results.slice(0, 3).map((result) => ({
                                name: result.name,
                                formatted_address: result.formatted_address,
                                geometry: result.geometry.location,
                            }));
                            this.gmaps.suggestions = suggestions;
                        } else {
                            console.error("Place search was not successful for the following reason: " + status);
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            },
            markerCallback(e) {
                let coords = {
                    location: {
                        lat: e.lat(),
                        lng: e.lng(),
                    },
                };
                this.selectLocation(coords);
            },
            validateStage() {
                if (!this.bodyForm.company && this.$store.state.userData.public_user) this.bodyForm.company = this.bodyForm.pic_name;
                const hasEmptyValues = Object.values(this.bodyForm).some((value) => value === "");

                if (!hasEmptyValues) {
                    $("#finalize").prop("disabled", false);
                } else {
                    $("#finalize").prop("disabled", true);
                }
            },
            changePic() {
                $("#pict").click();
            },

            showPreview() {
                let pic = document.getElementById("pict");
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImg");
                    preview.src = src;
                }
            },
            togglePass() {
                $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwd");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            togglePassConfirm() {
                $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwdconf");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            nextStage() {
                if (document.getElementById("pict").files.length > 0) {
                    this.photoFile = document.getElementById("pict");
                } else {
                    this.photoFile = null;
                }
                this.stages[this.stageIndex].status = 2;
                this.stages[this.stageIndex + 1].status = 1;
                $("#stages").scrollLeft("2000px");
                this.stageIndex++;
            },
            prevStage() {
                this.stages[this.stageIndex].status = 0;
                this.stages[this.stageIndex - 1].status = 1;
                $("#stages").scrollLeft("200px");
                this.stageIndex--;
            },
            photoInput() {
                $("#photoInput").click();
            },
            randomizeName() {
                return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021").toString().substring(0, 16);
            },
            logout() {
                localStorage.clear();
                location.reload();
            },
            currentMenu() {
                if (["Publish Rates", "Client Rates", "Request Change"].includes(this.$route.name)) {
                    return "Rates";
                }

                if (["Users", "References"].includes(this.$route.name)) {
                    return "Manages";
                }

                if (["Receipt", "SLA"].includes(this.$route.name)) {
                    return "Reports";
                } else {
                    return this.$route.name;
                }
            },
            currentSubmenu() {
                let menu = this.currentMenu();
                if (["Dashboard", "Receipts"].includes(menu)) {
                    return "";
                } else {
                    return this.$route.name;
                }
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        if (!this.$route.query.wv) this.$router.push("/signin");
                    });
            },
            encode64() {
                async function listarchivos(fileInput) {
                    var base64; //in this variable i need the base64
                    var fileToLoad = fileInput;
                    base64 = await getBase64(fileToLoad).then((data) => {
                        return data;
                    });
                    console.log(base64);
                    return base64;
                }

                //This is my function for get base64, but not return the string base64
                function getBase64(file) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                        return Promise.resolve(reader.result);
                    });
                }

                let result = listarchivos(this.photoFile);
                return result;
            },
            updateTable() {
                this.$refs.adminTable.this.getAllAdmin();
            },
            register() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .post(this.$store.state.app.apiBasepath + "/recipient-company/add", this.bodyForm, { headers: { Authorization: `Bearer ${token}` } })
                    .then(() => {
                        this.$store.state.app.watchForUpdates = true;
                        setTimeout(() => {
                            this.$store.state.app.watchForUpdates = false;
                        }, 500);
                        this.$alertSys("New Recipient Created!", "success");
                        this.$store.state.app.openModal = "";
                        this.$store.state.app.screenBlock = true;
                        setTimeout(() => {
                            this.$store.state.app.screenBlock = false;
                            const redir = sessionStorage.getItem("bookingAddClient");
                            if (redir) {
                                this.$store.state.app.openModal = "bookingCreate";
                                sessionStorage.removeItem("bookingAddClient");
                            }
                        }, 50);
                    })
                    .catch(() => {
                        this.$alertSys("Destination in " + this.bodyForm.city + " already exists!", "err");
                    });
            },
            ascSort() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return -1;
                    if (a.full_name > b.full_name) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.managerForm.allAdmins.sort(compare);
                this.paginate();
            },
            descSort() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return 1;
                    if (a.full_name > b.full_name) return -1;
                }

                this.page = 1;
                this.sortType = "DESC";
                this.$store.state.app.managerForm.allAdmins.sort(compare);
                this.paginate();
            },
            getAllAdmins() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/user/all-admin", { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log("200: " + res);
                        this.$store.state.app.managerForm.allAdmins = res.data.rows;
                        this.ascSort();
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            paginate() {
                // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
                for (let i = 0; i < this.totalPages; i++) {
                    this.$store.state.app.managerForm.filteredAdmins[i] = JSON.parse(JSON.stringify(this.$store.state.app.managerForm.allAdmins.slice(i * 6, (i + 1) * 6)));
                }
            },
        },
        mounted() {
            this.debouncedOnChange = _.debounce(() => {
                this.suggestionResult();
            }, 700);
        },
    };
</script>

<style lang="scss" scoped>
    #suggestions {
        position: absolute;
        z-index: 9999;
        margin: 0 10px;
        border-radius: 6px;
        width: 510px;
    }

    .suggestion {
        width: 90%;
        background-color: white;
        cursor: pointer;
        transition: all 0.25s ease;
    }

    .suggestion:hover {
        color: #3699ff;
    }

    #forms::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    #forms.profile {
        max-height: 60%;
    }
    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        position: relative;
        bottom: 0;
        right: 0;
    }

    #stages > .col-auto {
        opacity: 0.5;
    }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: absolute;
        top: 180px;
        left: 53%;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #stages::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
    border: 1px solid red;
  } */
</style>
