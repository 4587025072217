<template>
    <div class="container">
        <div class="header">
            <div class="title">{{ current.full_name }}</div>
            <div @click="$store.state.app.openModal = ''" id="closeModal"><img class="d-flex justify-content-center" src="/icons/all/outline/add.svg" /></div>
        </div>
        <div class="content">
            <div class="title">Receipt Report ({{ current.receiptList.length }})</div>

            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th v-for="(head, index) in headers" :key="index">{{ head }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(receipt, index) in current.receiptList" :key="index">
                            <td v-for="(data, index) in receipt" :key="'key' + index">{{ index }}{{ formatCurrency(data, index) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="footer">
            <div></div>
            <div class="subtotal">
                <div>Subtotal</div>
                <div>Rp {{ subTotal.toLocaleString() }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                headers: ["Date", "Receipt Number", "Origin", "Destination", "Weight", "Bag", "Price per Kg", "Unit Price", "Additional Fee", "Discount", "Amount"],
            };
        },
        computed: {
            current() {
                return this.$store.state.app.modalData.data;
            },
            subTotal() {
                let sub = 0;

                this.current.receiptList.forEach((rec) => {
                    sub += rec.amount;
                    delete rec.sla_grade;
                    delete rec.bookingReceiptId;
                    delete rec.receiptId;
                });

                return sub;
            },
        },
        methods: {
            formatCurrency(data, key) {
                const valid = ["price_per_kg", "unit_price", "additional_fee", "discount", "amount"];

                if (valid.includes(key)) return Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data);
                else return data;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .container {
        width: 65vw;
        // transform: translateX(-10vw);
    }
    .header {
        display: flex;
        justify-content: space-between;

        font-size: 18px;
        font-weight: 700;
        padding: 12px 0;

        #closeModal {
            transform: rotate(45deg);
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s;
            border: 1px solid transparent;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;

        font-size: 16px;
        font-weight: 700;
        padding: 12px 0;

        .subtotal {
            display: flex;
            gap: 22px;
            color: #3699ff;
        }
    }

    .content {
        text-align: left;
        width: 100%;
        overflow: auto;

        .table-container {
            width: 100%;
            max-height: 45vh;
            overflow: auto;
        }
        .title {
            padding: 0 12px;
            font-size: 16px;
            color: #999;
            font-weight: 700;
        }
        table {
            width: 100%;

            text-align: left;

            th {
                position: sticky;
                top: 0;
                background-color: #fff;
                z-index: 1;
                min-width: fit-content;
                text-wrap: nowrap;
                padding: 12px;
            }

            td {
                min-width: fit-content;
                text-wrap: nowrap;
                padding: 12px;
            }
        }
    }
</style>
